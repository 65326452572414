const theme = '';

const setTheme = () => {
  document.body.className = theme;
};

/**
 * 设置font-size
 * @param fontSize
 */
const initRootFontSize = (fontSize: number) => {
  document.getElementsByTagName('html')[0].style.fontSize = `${fontSize}px`;
};

const solemnArticleIds: string[] = [];

const initSolemn = () => {
  const { href } = window.location;
  const isNeedFilter = solemnArticleIds.some((id) => id && href.includes(id));
  if (isNeedFilter) {
    const bodyDom = document.body;
    bodyDom.style.filter = 'grayscale(100%)';
    bodyDom.style.transition = 'filter';
  }
};

const appInit = () => {
  window.initJs = (size: number) => {
    switch (size) {
      case 1: {
        initRootFontSize(16);
        break;
      }
      case 2: {
        initRootFontSize(18);
        break;
      }
      case 3: {
        initRootFontSize(20);
        break;
      }
      case 4: {
        initRootFontSize(22);
        break;
      }
      case 5: {
        initRootFontSize(24);
        break;
      }
      default: {
        initRootFontSize(18);
        break;
      }
    }
  };
};

(() => {
  appInit();
  setTheme();
  initSolemn();
})();
